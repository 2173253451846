// General Styles //
@import '../_style-index';

// Page Styles //
// General Styles for Index Page //
main {
  padding: 0;

  > section {
    padding: 0 5% 6% 5%;

    &:nth-child(even) {
      background-color: white; } } }

// Welcome Section //
.welcome-section {
  background: {
    image: url('https://res.cloudinary.com/jr-cologne-de/image/upload/c_fit,f_auto,fl_lossy,q_auto,w_1920/welcome-section-bg.jpg');
    size: cover;
    position: 12% center;
    repeat: no-repeat; }
  height: calc(100vh - 80px);
  padding: 0;
  overflow: hidden;

  .welcome-section-overlay {
    display: block;
    height: inherit;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6%; }

  .welcome-heading-container {
    flex: 1; }

  .welcome-text, h1 {
    display: block;
    font-family: $secondary-font;
    font-weight: normal;
    margin: 0;
    color: $white-gray; }

  .welcome-text {
    font-size: 3.5rem;
    margin-bottom: 1%; }

  h1 {
    font-size: 4rem; } }
